import clsx from "clsx"
import React from "react"
import { CgSpinner } from "react-icons/cg"

type SpinnerProps = {
    size?: string
    color?: string
    className?: string
}

const Spinner = ({
    size = "text-xl",
    color = "text-black",
    className,
}: SpinnerProps) => {
    const spinnerClass = clsx(`animate-spin ${size} ${color}`, className)
    return <CgSpinner className={spinnerClass} />
}

export default Spinner
