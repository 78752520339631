import * as Sentry from "@sentry/nextjs"
import clsx from "clsx"

import type { NextPage } from "next"
import Head from "next/head"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import React, { useState } from "react"
import { useRecoilCallback } from "recoil"

import { Button } from "@components/buttons"
import Input from "@components/input"
import Spinner from "@components/spinner"
import api from "@lib/api/routes"
import { parseError } from "@lib/errors"
import { validateEmail } from "@lib/helpers"
import { lastAuthState } from "@state/auth"
import { setOrganizationUser, setOrganizationUsers } from "@state/organizations"

const LoginPage: NextPage = () => {
    const router = useRouter()
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState<string | null>(null)
    const [password, setPassword] = useState("")
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const [loggingIn, setLoggingIn] = useState(false)
    const valid = email && validateEmail(email) && password

    // Validate Email
    const onChangeEmail = (email: string) => {
        setEmail(email)
    }

    const onValidateEmail = (email: string) => {
        if (email === "" || validateEmail(email)) {
            setEmailError(null)
        } else {
            setEmailError("Incorrect format.")
        }
    }

    const onChangePassword = (password: string) => {
        setPassword(password)
    }

    const onLogin = useRecoilCallback(({ set }) => async (e: React.FormEvent) => {
        e.preventDefault()

        try {
            setLoggingIn(true)

            // Login response will set auth token in cookie
            const data = await api.login({ email, password })
            set(lastAuthState, new Date())

            setOrganizationUser(data.user.organization_user[0])
            setOrganizationUsers(data.user.organization_user)

            // Nav to home page
            router.push("/")
        } catch (err) {
            Sentry.captureException(err)
            console.error(err)
            setError(parseError(err))

            setLoggingIn(false)
        }
    })

    const loginButtonClassName = clsx(
        "w-full flex p-5 justify-center text-xl font-bold text-white",
        {
            "bg-blue-400": !valid,
            "bg-blue-600 hover:bg-blue-700": valid,
        },
    )

    return (
        <>
            <Head>
                <title>Log In - Nuve Platform</title>
            </Head>

            <div className="flex min-h-full justify-center bg-slate-100 lg:items-center">
                <div className="w-full border border-slate-200 bg-white p-6 lg:max-w-xl lg:rounded-md lg:p-10">
                    <h2 className="flex flex-col gap-4 text-3xl font-medium lg:flex-row">
                        <Image
                            src="/images/nuve-logo.svg"
                            width={75}
                            height={25}
                            alt="logo"
                        />
                        <span>Log in to Nuve Platform</span>
                    </h2>

                    <form className="mt-12 space-y-6" onSubmit={onLogin}>
                        <div className="flex flex-col gap-1">
                            <Input
                                type="email"
                                name="Email"
                                value={email}
                                placeholder="name@example.com"
                                disabled={loggingIn}
                                onChange={onChangeEmail}
                                onBlur={onValidateEmail}
                                autoFocus={true}
                                error={emailError}
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <Input
                                type={showPassword ? "text" : "password"}
                                name="Password"
                                value={password}
                                placeholder="••••••••••"
                                disabled={loggingIn}
                                onChange={onChangePassword}
                            />
                        </div>
                        <div className="mb-4 flex items-center">
                            <input
                                id="showPassword"
                                type="checkbox"
                                className="rounded-sm border border-slate-500"
                                onClick={() => {
                                    setShowPassword(!showPassword)
                                }}
                            />
                            <label htmlFor="showPassword" className="ml-3 text-sm">
                                Show password
                            </label>
                        </div>

                        <div className="space-y-2">
                            {error && (
                                <p className="rounded bg-red-100 px-2 py-1 text-center text-sm text-red-600">
                                    {error}
                                </p>
                            )}
                            <Button
                                onClick={onLogin}
                                className={loginButtonClassName}
                                disabled={!valid || loggingIn}
                            >
                                {loggingIn ? (
                                    <>
                                        <Spinner
                                            size="text-md"
                                            color="text-white"
                                            className="mt-1"
                                        />
                                        <span className="block">Logging in...</span>
                                    </>
                                ) : (
                                    <span className="block">Log in &rarr;</span>
                                )}
                            </Button>
                        </div>
                    </form>

                    <div className="mt-5 flex justify-center">
                        <Link
                            href="/auth/forgot"
                            className="text-sm text-blue-500 hover:text-blue-800"
                        >
                            Forgot your password?
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoginPage
