import clsx from "clsx"
import React from "react"

type InputProps = {
    type?: string
    name: string
    value: string
    placeholder?: string
    onChange?: (value: string) => void
    onBlur?: (value: string) => void
    disabled?: boolean
    error?: string | null
    autoFocus?: boolean
    className?: string
}

const Input = ({
    type = "text",
    name,
    value,
    placeholder,
    onChange,
    onBlur,
    disabled = false,
    error,
    autoFocus = false,
    className,
}: InputProps) => {
    const _onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(e.target.value)
        }
    }

    const _onBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onBlur) {
            onBlur(e.target.value)
        }
    }

    const labelClassName = clsx("font-medium text-sm", className)
    const inputClassName = clsx("block w-full rounded-sm input", {
        "border-slate-300": !error,
        "border-2 border-red-700": error,
    })

    if (!placeholder) {
        placeholder = name
    }

    return (
        <div className="flex w-full flex-col gap-1">
            <label className={labelClassName}>{name}</label>
            <input
                type={type}
                className={inputClassName}
                value={value}
                onChange={_onChange}
                onBlur={_onBlur}
                placeholder={placeholder}
                disabled={disabled}
                autoFocus={autoFocus}
            />
            {error && <p className="text-xs text-red-600">{error}</p>}
        </div>
    )
}

export default Input
