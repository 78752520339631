import React from "react"
import { twMerge } from "tailwind-merge"

type ButtonProps = {
    type?: string
    children: React.ReactNode
    onClick: (args: any) => any
    className?: string
    disabled?: boolean
    title?: string
}

export const Button = ({
    className,
    children,
    onClick,
    type = "button",
    disabled = false,
    title,
}: ButtonProps) => {
    const buttonClass = twMerge(
        "flex gap-2 items-center px-3 py-[0.35rem] rounded font-medium",
        className,
        type == "a" && "cursor-pointer",
    )
    if (type == "a") {
        if (disabled) {
            onClick = () => {
                return false
            }
        }
        return (
            <a className={buttonClass} onClick={onClick} title={title}>
                {children}
            </a>
        )
    } else {
        return (
            <button
                className={buttonClass}
                onClick={onClick}
                disabled={disabled}
                title={title}
            >
                {children}
            </button>
        )
    }
}

export const SecondaryButton = (props: ButtonProps) => {
    const buttonClass = twMerge(
        "bg-white hover:bg-slate-50 border border-slate-300 text-slate-800",
        props.className,
    )
    return (
        <Button
            type="a"
            className={buttonClass}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </Button>
    )
}

export const PrimaryButton = (props: ButtonProps) => {
    const buttonClass = twMerge(
        "border border-transparent text-white",
        props.className,
        props.disabled && "bg-blue-300",
        !props.disabled && "bg-blue-500 hover:bg-blue-600",
    )
    return (
        <Button
            className={buttonClass}
            onClick={props.onClick}
            type={props.type}
            disabled={props.disabled}
            title={props.title}
        >
            {props.children}
        </Button>
    )
}
