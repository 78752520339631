// TODO: i18n

export function parseError(err: any) {
    let message = "Unknown error"

    if (err.data instanceof Error || err.data.message) {
        message = err.data.message
    } else if (typeof err.data == "string") {
        message = err.data
    }

    // Rewrite user-unfriendly errors
    if (message == "Failed to fetch") {
        message = "Could not connect"
    }

    return message
}

// export function parseAPIError(error: string)
